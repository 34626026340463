const pricingRouter = {
  route: null,
  name: null,
  title: '价格管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-yunyingguanli_fuwufenzuguanli',
  filePath: 'view/pricing/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '价格列表',
      type: 'view',
      name: 'PricingList',
      route: '/pricing/list',
      filePath: 'view/pricing/pricing-list.vue',
      inNav: true,
      icon: 'iconfont icon-yunyingguanli_fuwufenzuguanli',
    },
    {
      title: '添加价格',
      type: 'view',
      name: 'PricingCreate',
      route: '/pricing/add',
      filePath: 'view/pricing/pricing.vue',
      inNav: true,
      icon: 'iconfont icon-add',
    },
  ],
}

export default pricingRouter
