const rulesRouter = {
  route: null,
  name: null,
  title: '规则管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-pinglun',
  filePath: 'view/rules/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '规则列表',
      type: 'view',
      name: 'RulesList',
      route: '/rules/list',
      filePath: 'view/rules/rules-list.vue',
      inNav: true,
      icon: 'iconfont icon-pinglun',
    },
    {
      title: '添加规则',
      type: 'view',
      name: 'RulesCreate',
      route: '/rules/add',
      filePath: 'view/rules/rules.vue',
      inNav: true,
      icon: 'iconfont icon-add',
    },
  ],
}

export default rulesRouter
