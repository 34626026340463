const init = {
  route: null,
  name: null,
  title: '初始化管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-daima1',
  filePath: 'view/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '会员商店列表',
      type: 'view',
      name: 'MembermallList',
      route: '/membermall/list',
      filePath: 'view/membermall/membermall-list.vue',
      inNav: true,
      icon: 'iconfont icon-huiyuan1',
    },
    {
      title: '视频教程列表',
      type: 'view',
      name: 'Machinetutorial',
      route: '/machinetutorial/list',
      filePath: 'view/machinetutorial/machinetutorial-list.vue',
      inNav: true,
      icon: 'iconfont icon-kecheng',
    },
    {
      title: '洗车点信息列表',
      type: 'view',
      name: 'Mashsite',
      route: '/washsite/list',
      filePath: 'view/washsite/washsite-list.vue',
      inNav: true,
      icon: 'iconfont icon-jiemianicon-',
    },
    {
      title: '用户列表',
      type: 'view',
      name: 'User',
      route: '/user/list',
      filePath: 'view/user/user-list.vue',
      inNav: true,
      icon: 'iconfont icon-huiyuanguanli',
    },
  ],
}

export default init
